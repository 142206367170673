import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { youtube } from 'react-icons-kit/fa/youtube';
import { twitter } from 'react-icons-kit/fa/twitter';
import { instagram } from 'react-icons-kit/fa/instagram';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, { List, ListItem, SocialList }  from './footer.style';

import LogoImage from 'common/src/assets/image/app/flairboat.png';
const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: 'https://www.facebook.com/FlairBoatOfficial/',
  },
  {
    id: 2,
    icon: <Icon icon={instagram} />,
    name: 'instagram',
    link: 'https://www.instagram.com/flairboat/',
  },
  {
    id: 3,
    icon: <Icon icon={linkedin} />,
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/flairboat/',
  },
  {
    id: 4,
    icon: <Icon icon={youtube} />,
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCLgWAQJlNg3McsRSQF0wpsA/featured?view_as=subscriber',
  },
  {
    id: 5,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: 'https://twitter.com/Flairboat5',
  },
];
const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      appJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper id="footerSection">
      <Container>
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="https://flairboat.com"
              logoSrc={LogoImage}
              title="Flairboat"
              logoStyle={logoStyle}
            />
            {/* <Text content="contact@flairboat.com" {...textStyle} /> */}
            {/* <Text content="+479-443-9334" {...textStyle} /> */}
            <SocialList>
                {socialLinks.map(item => (
                  <li className={item.name} key={`link-key${item.id}`}>
                    <a aria-label={item.name} href={item.link} target="_blank">
                      {item.icon}
                    </a>
                  </li>
                ))}
              </SocialList>
          </Box>
          {/* End of footer logo column */} 
          <Box {...colTwo}>
            {Data.appJson.menuWidget.map(widget => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map(item => (
                    <ListItem key={`list__item-${item.id}`}>
                      <a className="ListItem" href={item.url}>
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyright" {...row}>
            <Text className="text" content="© 2020 Flairboat Pvt. Ltd. , All rights Reserved" />
            <p>
              Made with
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.243"
                height="10.378"
                viewBox="0 0 11.243 10.378"
              >
                <path
                  id="f141"
                  d="M10.324-44.135a3.191,3.191,0,0,1,.919,2.311,3.191,3.191,0,0,1-.919,2.311l-4.7,4.892-4.7-4.892A3.191,3.191,0,0,1,0-41.825a3.191,3.191,0,0,1,.919-2.311A2.618,2.618,0,0,1,2.905-45a2.618,2.618,0,0,1,1.986.865l.73.757.757-.757A2.6,2.6,0,0,1,8.351-45,2.6,2.6,0,0,1,10.324-44.135Zm0,0"
                  transform="translate(0 45)"
                  fill="#f4291e"
                />
              </svg>
               &nbsp; in 
               <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 225 150" width="17" height="10">
                 <rect width="225" height="150" fill="#f93"/>
                 <rect width="225" height="100" y="50" fill="#fff"/>
                 <rect width="225" height="50" y="100" fill="#128807"/>
                 <g transform="translate(112.5,75)"><circle r="20" fill="#008"/>
                 <circle r="17.5" fill="#fff"/><circle r="3.5" fill="#008"/><g id="d"><g id="c"><g id="b"><g id="a"><circle r="0.875" fill="#008" transform="rotate(7.5) translate(17.5)"/>
                 <path fill="#008" d="M 0,17.5 0.6,7 C 0.6,7 0,2 0,2 0,2 -0.6,7 -0.6,7 L 0,17.5 z"/></g>
                 <use href="#a" transform="rotate(15)"/></g><use href="#b" transform="rotate(30)"/></g>
                 <use href="#c" transform="rotate(60)"/></g><use href="#d" transform="rotate(120)"/><use href="#d" transform="rotate(-120)"/></g>
               </svg>
            </p>
          </Box>
          {/* End of copyright row */}
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['50%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
  },
  // Default logo size
  logoStyle: {
    width: '168px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
