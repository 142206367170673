import styled from 'styled-components';
import { themeGet } from 'styled-system';


const HeaderWrapper = styled.header`
  // background-color: #fff;
  padding: 15px 0;
  .sticky-nav-active {
    .hosting_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
    }
  }
`;

export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }
  .menubar {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
`;

export const Logo = styled.div`
  margin-right: 50px;
  max-width : 310px;
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    margin-right: 25px;
  }
`;

export const PrimaryNav = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .primaryNav {
    display: flex;
    width: 100%;
    li {
      a:active {
        // background-color: #FFC845;

      }
     
      .activation{
        &.active {
        
          color: #1A73E8;
          font-weight: 700;
          background-shadow: 0 40px 90px -30px rgba(224, 229, 235, 0.2);
          
        }
       
      }
      a {
        color: ${themeGet('colors.textPrimary', '#02073E')};
        display: inline-flex;
        font-weight: 400;
        &.active{
          color: #1A73E8;
          font-weight: 700;
          background-shadow: 0 40px 90px -30px rgba(224, 229, 235, 0.2);
          // color: ${themeGet('colors.secondary')};
          }
        &:hover {
          // opacity: 0.85;
          color: #1A73E8;
          font-weight: 500;
          cursor: pointer;
          background-shadow: 0 40px 90px -30px rgba(224, 229, 235, 0.2);
        }
      }
      margin-right: 24px;
      // &:last-child {
      //   margin-left: auto;
      //   color: #fffff;
      // }
      
    }
    .is-current  a {
      
      color: #1A73E8;
      font-weight: 700;
      background-shadow: 0 40px 90px -30px rgba(224, 229, 235, 0.2);
      // color: ${themeGet('colors.secondary')};
    }
  }
  .joinButton {
    white-space: nowrap;
    font-size: 13px;
        font-weight: 600;
        border-radius: 4px;
         min-width: auto;
         
        min-height: auto;
    button {
      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        font-size: 13px;
        
        font-weight: 600;
        border-radius: 4px;
        // min-width: auto;
        padding: 0 10px;
        min-height: auto;
      }
    }
  }
  .joinButton2 {
    margin-right:10px;
    white-space: nowrap;
    font-size: 13px;
        font-weight: 600;
        border-radius: 4px;
         min-width: auto;
       border: 1px solid #fff;
         
        min-height: auto;
    button {
      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        font-size: 13px;
        font-weight: 600;
        border-radius: 4px;
        // min-width: auto;
        padding: 0 10px;
        min-height: auto;
      }
    }
  }
`;

export const MobileNav = styled.nav`
  background-color: #fff;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: 0;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.15) 0 10px 13px 0px;
  top: 100%;
  transition: 0.3s ease 0s;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  .mobileNav {
    li {
      a {
        color: ${themeGet('colors.textPrimary', '#02073E')};
        display: flex;
        padding: 13px 0;
      }
      margin-right: 24px;
      &:last-child {
        margin-left: auto;
      }
    }
    .is-current a {
      color: ${themeGet('colors.secondary')};
    }
  }
  .joinButton {
    margin-top: 15px;
    display: block;
    width:100%;
    border-radius:4px;
  }
  .joinButton2 {
    margin-top: 15px;
    display: block;
    width:100%;
    border-radius:4px;

  }
`;

export default HeaderWrapper;
