import styled from 'styled-components';
import { themeGet } from 'styled-system';
import FooterIamge from 'common/src/assets/image/app/footer-bg.png';

const FooterWrapper = styled.section`
  padding: 50px 0;
  padding-top: 110px;
  margin-top: 40px;
  // background-image: url(${FooterIamge});
  background-color: #F6F9FC;
  background-repeat: no-repeat;
  background-position: center 50px;
  border-top: 1px solid #efefef;
  overflow: hidden;
  @media (max-width: 990px) {
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
  .copyright {
    font-size:14px;
    width: 100%;
    margin-top: 8px;
    padding-top: 10px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #212121;
    @media only screen and (max-width: 480px) {
      justify-content: center;
      margin-top: 10px;
      padding-top: 25px;
      flex-direction: column;
    }

    p {
      color: #464646;
      margin: 0;

      svg {
        margin-left: 10px;
      }
    }
  }
`;

const List = styled.ul``;
export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 30px;
  @media only screen and (max-width: 780px) {
    margin-top:10px;
  }
  li {
    margin-right: 30px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet('colors.lightText', '#7E7E7E')};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 15px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet('colors.primary', '#FDEF00')};
      }
    }
  }
`;
const ListItem = styled.li`
@media only screen and (max-width: 991px) {
  display:flex;
  flex-wrap:wrap;
}
  a {
    color: ${themeGet('colors.textColor', 'rgba(52, 61, 72, 0.8)')};
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet('colors.quoteText', '#343d48')};
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
