import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBDyXaV3X0a_O1C73kDnUO0CKtmQvjNcQM",
  authDomain: "flairboat-9a97e.firebaseapp.com",
  databaseURL: "https://flairboat-9a97e.firebaseio.com",
  projectId: "flairboat-9a97e",
  storageBucket: "flairboat-9a97e.appspot.com",
  messagingSenderId: "575556624599",
  appId: "1:575556624599:web:1d47777baaf8be4a",
  measurementId: "G-PPPBNM2TBG"
};

firebase.initializeApp(firebaseConfig);
export default firebase;