import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { androidMenu } from 'react-icons-kit/ionicons/androidMenu';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import Container from 'common/src/components/UI/Container';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';

import logo from 'common/src/assets/image/app/flairboat.png';
import HeaderWrapper, {
  HeaderInner,
  Logo,
  PrimaryNav,
  MobileNav,
} from './navbar.style';

const Navbar = () => {
  const Data = useStaticQuery(graphql`
    query {
      appJson {
        menuItemsMain {
          label
          path
          offset
        }
      }
      logo: file(relativePath: { eq: "image/app/flairboat.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleHandleMenuClose = () => {
    setMobileMenu(false);
  };

  return (
    <HeaderWrapper className="hosting_navbar">
      <Container>
        <HeaderInner>
        <a href="https://flairboat.com">
            <Logo>

              <Image src={logo} alt="Flairboat" />
            </Logo>
          </a>
          <PrimaryNav>
            <Scrollspy
              className="primaryNav menu-items"
              
              offset={-70}>
                {Data.appJson.menuItemsMain.map((menu, index) => (
            <li key={`menu_key${index}`}>
              <Link className="activation"
                to={menu.path}
                activeStyle={{ color: "#1559FB" }}
              >
                {menu.label}
              </Link>
            </li>
          ))}
              </Scrollspy>
            
            {/* <a href="https://app.flairboat.com">
              <Button className="joinButton2" title="LOGIN" />
              </a> */}
            {/* <Link to={`/#`} className="joinButton">
              <Button className="joinButton" title="REGISTER" />
            </Link> */}
          </PrimaryNav>
          <Button
            className="menubar"
            icon={
              mobileMenu ? (
                <Icon
                  style={{ color: '#02073E' }}
                  className="bar"
                  size={32}
                  icon={androidClose}
                />
              ) : (
                <Fade>
                  <Icon
                    style={{ color: '#02073E' }}
                    className="close"
                    icon={androidMenu}
                    size={32}
                  />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={handleMobileMenu}
          />
        </HeaderInner>
      </Container>

      <MobileNav className={mobileMenu ? 'is-active' : ''}>
        <Scrollspy className="mobileNav" offset={-70}>
          {Data.appJson.menuItemsMain.map((menu, index) => (
            <li key={`menu_key${index}`}>
              <Link
                to={menu.path}
                offset={menu.offset}
                onClick={handleHandleMenuClose}
              >
                {menu.label}
              </Link>
            </li>
          ))}
        </Scrollspy>
        {/* <a href="https://app.flairboat.com" className="joinButton">
        <Button className="joinButton" title="LOGIN" />
        </a> */}
        {/* <Link to={`/#`} className="joinButton">
        <Button className="joinButton" title="REGISTER" />
        </Link> */}
      </MobileNav>
    </HeaderWrapper>
  );
};

export default Navbar;
